<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        class="mr-1"
        :to="{name:'assign-entity-member'}"
      >
        Assign Member
      </b-button>

      <b-button
        v-if="$can('createUserToEntity','EntityMemberController')"
        variant="primary"
        :to="{name:'add-entity-member'}"
      >
        Add New Member
      </b-button>
    </div>

    <b-card
      header="Search"
      class="p-1"
    >
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <search
            placeholder="Username"
            :search-query.sync="searchQuery.username"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :items="usersProvider"
        :fields="tableColumns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        show-empty
        empty-text="No users in this entity"
        responsive
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="isThisUserNotAuthUser(item)"
              :to="{ name: 'edit-user-permissions', params: { username: item.username } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit User Permissions </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isThisUserNotAuthUser(item)"
              :to="{ name: 'edit-user-roles', params: { username: item.username } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit User Roles </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isThisUserNotAuthUser(item)"
              @click="deleteMember(item.username)"
            >
              <div
                class="text-danger"
              >
                <feather-icon icon="XCircleIcon" />
                <span> Delete </span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'PortalUsers',
  components: { Pagination, Search, SearchButton },
  data() {
    return {
      searchQuery: {},
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'id' },
        { key: 'username' },
        { key: 'email' },
        { key: 'profile.first_name', label: 'First Name' },
        { key: 'profile.last_name', label: 'Last Name' },
        { key: 'profile.phone', label: 'Phone Number' },
        { key: 'actions' },
      ]
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulDeletionAlert, confirmOperation } = handleAlerts()
    return { successfulDeletionAlert, confirmOperation, pagination }
  },
  methods: {
    usersProvider() {
      return this.$portalUsers.get(`v1/entity/member/all/${this.$store.getters['mainEntity/getEntityId']}`, {
        params: {
          ...this.searchQuery,
          page: this.pagination.currentPage,
        },
      })
        .then(res => {
          const users = res.data.data

          this.pagination.totalRows = res.data.pagination.total
          return users || []
        }).catch(() => [])
    },
    deleteMember(username) {
      this.confirmOperation().then(() => {
        this.$portalUsers.delete('v1/member/revoke', {
          data: {
            entityId: this.$store.getters['mainEntity/getEntityId'],
            member: username,
          },
        }).then(() => {
          this.successfulDeletionAlert('Member is deleted successfully')
          this.$refs.table.refresh()
        })
      })
    },
    isThisUserNotAuthUser(user) {
      return user.id !== this.$store.getters['auth/getUserData'].id
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
